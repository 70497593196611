// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "v_c9";
export var qaBenefitsSection = "v_cZ";
export var qaBusinessSection = "v_c0";
export var qaCtaSection = "v_c5";
export var qaCtaSection__cta = "v_c6";
export var qaDomainExpertiseSection = "v_c3";
export var qaProjLogoSection = "v_c8";
export var qaResultSection = "v_c2";
export var qaServicesSection = "v_cW";
export var qaTechStackSection = "v_c4";
export var qaTestimonialPost = "v_c1";
export var qaTestingServicesSection = "v_cX";
export var qaVideoClientSection = "v_db";
export var qaWhenDoYouNeedSection = "v_cY";
export var qaWhyChooseSection = "v_c7";